import { Icon } from '@iconify/react';

import { classNames } from '../../../lib/classNames';
import { Tooltip } from '../Tooltip';
import { WarnTooltip } from '../WarnTooltip';
import { CalculationInput } from './CalculationInput';
import { Reference } from './Reference';
import { useCommonCalculationProps } from './useCommonCalculationProps';

const className = 'text-black mr-1';

export function Sign({ sign }) {
	switch (sign) {
	case '+':
		return <Icon icon='lucide:plus' className={className} />;
	case '-':
		return <Icon icon='lucide:minus' className={className} />;
	case '*':
		return <Icon icon='lucide:x' className={className} />;
	case '/':
		return <Icon icon='lucide:divide' className={className} />;
	case '=':
		return <span style={{ fontWeight: 'bold', color: '#111' }}>=</span>;
	default:
		return null;
	}
}

export function CalculationTableRowTerm({
	sign,
	value,
	reference,
	unit,
	editable,
	onValueChange,
	mustBeInteger,
	decimals,
	wide = false,
	infoMessage,
	topText,
	...props
}) {
	const { warningMessage, isDirty } = useCommonCalculationProps({ ...props, value });

	return <div className="calc-row-term">
		<label className='calc-row-term-content'>
			{sign && <div className='sign-wrapper'>
				<Sign sign={sign} />
			</div>}
			<div className={classNames('calc-row-term-field', wide && 'calc-row-term-field-wide')}>
				{topText && <h3>{topText}</h3>}
				<CalculationInput
					{...props}
					value={value}
					decimals={decimals}
					editable={editable}
					onValueChange={onValueChange}
					isDirty={isDirty}
					mustBeInteger={mustBeInteger} />
			</div>
			<div>
				{warningMessage && <WarnTooltip text={warningMessage} />}

				<span className='calc-row-term-unit'> {unit}</span>{' '}

				{infoMessage && <Tooltip text={infoMessage}>
					<Icon icon='heroicons-solid:information-circle' className='h-5 w-5 text-blue-300' />
				</Tooltip>}
			</div>
			<div style={{ flex: 1 }} />
			{reference && <Reference reference={reference} isDirty={isDirty} />}
		</label>
	</div>;
}
