import { useEffect, useState } from 'react';

const getNestedSections = (sectionElements, nodeName) => {
	const nestedSections = [];

	sectionElements.forEach((section) => {
		const { innerText: title, id } = section;

		if (section.nodeName === nodeName) {
			nestedSections.push({ id, title, items: [] });
		}
		// else if (section.nodeName === 'H3' && nestedHeadings.length > 0) {
		// 	const innerHeading = nestedHeadings[nestedHeadings.length - 1];
		// 	innerHeading.items.push({ id, title });
		// }
	});

	return nestedSections;
};

export function useFindSectionsForScrollSpy(selectors, nodeName) {
	const [ elements, setElements ] = useState([]);
	useEffect(() => {
		const sectionElements = Array.from(document.querySelectorAll(selectors));
		setElements(getNestedSections(sectionElements, nodeName));
	}, [ nodeName, selectors ]);
	return { elements };
}
