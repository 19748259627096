import React, { useState } from 'react';
import { Icon } from '@iconify/react';

export default function FaqCollapsableArea({ label, children, defaultOpen = false }) {
	const [ open, setOpen ] = useState(defaultOpen);

	return <div className="faq-collapsable-area" onClick={() => (open ? null : setOpen(true))}>
		<div className={open ? "faq-collapsable-area-header-open" : "faq-collapsable-area-header-closed"} onClick={() => setOpen(!open)}>
			<span className="faq-collapsable-area-label">
				<div className="faq-collapsable-area-icon">
					<Icon icon="akar-icons:circle-chevron-right-fill" />
				</div>
				{/* <span style={open ? { color: 'var(--col-primary-100)' } : { color: 'var(--col-primary-800)' }} className={open ? "fa fa-angle-down" : "fa fa-angle-right"} />*/}
				{/* &nbsp;*/}
				{/* &nbsp;*/}
				<span className={open ? "faq-collapsable-area-text-open" : "faq-collapsable-area-text-closed"}>{label}</span>
			</span>
		</div>
		{open && <div className="faq-collapsable-area-content">
			{children}
		</div>}
	</div>;
}
