import { Icon } from '@iconify/react';
import { useContext } from 'react';

import { classNames } from '../../../lib/classNames';
import { BouwCalculatorReducerContext } from '../../../reducers/StepsStoreProvider';

const highlightClassName = [ 'reference-highlight' ];

function highlight(id) {
	const element = document.querySelector(`#${id}`);
	if (element) {
		element.scrollIntoView({ behavior: 'smooth', block: 'center' });
		element.classList.add(...highlightClassName);

		// Highlight the element for 2 seconds
		setTimeout(() => {
			element.classList.remove(...highlightClassName);
		}, 2000);
	}
}

export function Reference({ reference, isDirty }) {
	const [ _, dispatch ] = useContext(BouwCalculatorReducerContext);
	return (
		<span
			title={`Ga naar ${reference.text}${reference.step ? ' in ' + reference.step : ''}`}
			className={classNames('reference', isDirty && 'line-through')}
			onMouseOver={() => {
				const element = reference.id && document.querySelector(`#${reference.id}`);
				if (element) {
					element.classList.add(...highlightClassName);
				}
			}}
			onMouseOut={() => {
				const element = reference.id && document.querySelector(`#${reference.id}`);
				if (element) {
					element.classList.remove(...highlightClassName);
				}
			}}
			onClick={() => {
				if (reference.step) {
					dispatch({ type: 'step/updateStep', step: reference.step });

					// Highlight the element after a second (give time to scroll into view first)
					setTimeout(() => {
						highlight(reference.id);
					}, 1000);
				} else {
					highlight(reference.id);
				}
			}}>
			{reference.text}
			&nbsp;&nbsp;
			<Icon className='w-5 h-5 ml-2 text-gray-500' icon='codicon:references' />
		</span>
	);
}
