import { useContext, useState } from 'react';
import Modal from 'react-modal';
import Button from './lib/forms/Button';
import { BouwCalculatorReducerContext, localstorageKey } from '../reducers/StepsStoreProvider';
import ButtonArea from './lib/forms/ButtonArea';

export default function SuggestReusingState() {
	const [ _, dispatch ] = useContext(BouwCalculatorReducerContext);
	const [ dismissed, setDismissed ] = useState(localStorage.getItem(localstorageKey) == null);

	return <Modal
		isOpen={!dismissed}
		onRequestClose={() => setDismissed(true)}
		style={{ content: { border: 'none', background: 'transparent' }, overlay: { background: 'rgba(0, 0, 0, 0.5)' } }}>
		<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<div style={{ maxWidth: '450px' }} className="modal-card">
				<h2>Gegevens overnemen?</h2>
				<div style={{ padding: 'var(--u-8) 0' }}>
					<p>Er zijn gegevens gevonden van de vorige keer dat je de haalbaarheidsscan gebruikte, wil je verdergaan met deze gegevens?</p>
				</div>
				<ButtonArea>
					<Button onClick={() => setDismissed(true)}>Begin opnieuw</Button>
					<Button primary onClick={() => {
						try {
							const lsState = JSON.parse(localStorage.getItem(localstorageKey));
							lsState.stepReducer.seenSteps = new Set(lsState.stepReducer.seenSteps);
							dispatch({ type: 'replaceState', state: lsState });
						} catch (e) {
							// eslint-disable-next-line no-console
							console.error(e);
						} finally {
							setDismissed(true);
						}
					}}>Ja, verdergaan</Button>
				</ButtonArea>
			</div>
		</div>
	</Modal>;
}