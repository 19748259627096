import { Icon } from '@iconify/react';
import { classNames } from '../../lib/classNames';

export const AlertType = {
	Success: 'success',
	Error: 'error',
	Info: 'info',
	Warning: 'warning',
};

export function Alert({ title, text, type = AlertType.Info }) {
	return <div className={'alert alert-' + type}>
		<div className="alert-icon">
			{type == AlertType.Success && <Icon icon="heroicons-solid:check" />}
			{type == AlertType.Error && <Icon icon="heroicons-solid:exclamation" />}
			{type == AlertType.Info && <Icon icon="heroicons-solid:information-circle" />}
			{type == AlertType.Warning && <Icon icon="heroicons-solid:exclamation" />}
		</div>
		<div className="alert-content">
			{title && <h3>{title}</h3>}
			<div className="alert-msg">
				{text}
			</div>
		</div>
	</div>;
}
