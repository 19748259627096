export function CostRow({ name, children, helpText, cols }) {
	return <div className='cost-row'>
		<h3>{name}</h3>
		{helpText && <div className='remark'>{helpText}</div>}
		<div className={'cost-row-cols cost-row-cols-' + cols}>{children}</div>
	</div>;
}

export function CostRowFullWidth({ children }) {
	return <div className="cost-row-full-width">
		{children}
	</div>;
}