import { Icon } from '@iconify/react';

import { classNames } from '../../../lib/classNames';

export function CostColumn({ name, icon, iconComponent, children }) {
	return <div className="cost-col">
		<div className='cost-col-header'>
			{icon && <span className="cost-col-icon"><Icon icon={icon} /></span>}
			{iconComponent && iconComponent}
			<span className="cost-col-title">{name}</span>
		</div>
		<div className='cost-col-content'>{children}</div>
	</div>;
}
