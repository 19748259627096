import { Icon } from '@iconify/react';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { UnmountClosed } from 'react-collapse/lib/UnmountClosed';
import Scroll from 'react-scroll';

import { classNames } from '../../lib/classNames';
import { BouwCalculatorReducerContext } from '../../reducers/StepsStoreProvider';
import { CalculationInput } from '../lib/calctable/CalculationInput';
import { PROJECT_STEP } from './s1-project/ProjectStep';
import ValidatedButton from '../lib/forms/ValidatedButton';
import ButtonArea from '../lib/forms/ButtonArea';

export function hasValidValues(array) {
	return array?.some((value) => Number.isNaN(value) || value <= 0);
}

export function Step({ id, icon, name, description, children, summary, summaryComponent, wider, showContent, readyForNextStep, nextStepValidations, nextStep, thisStep, summaryInError = false }) {
	const [ state, dispatch ] = useContext(BouwCalculatorReducerContext);

	const hasSummary = summary || summaryComponent;
	// const hasValidSummaryValues = !summaryValues?.some((value) => Number.isNaN(value) || value <= 0);

	const stepAlreadyCompleted = state.stepReducer.seenSteps?.has(thisStep);
	const actualReadyForNextStep = readyForNextStep ?? (nextStepValidations ?? []).reduce((allValid, v) => allValid && v.valid, true);

	const showSummary = !showContent && hasSummary && stepAlreadyCompleted;
	const reference = useRef(null);

	const [ isOpened, setOpened ] = useState(false);

	useLayoutEffect(() => {
		if (!stepAlreadyCompleted || (thisStep === PROJECT_STEP && state.stepReducer.seenSteps.size <= 1)) return;
		if (showContent && isOpened && reference?.current) {
			const offset = reference.current.offsetTop - 50;
			Scroll.animateScroll.scrollTo(offset, {
				duration: 500,
				smooth: 'easeOutQuad',
			});
		}
	}, [ showContent, reference, isOpened ]);

	return <section aria-labelledby={id} className={classNames('card transition', stepAlreadyCompleted && 'card-clickable', showContent && 'card-active')}>
		<div className='card-top' onClick={() => stepAlreadyCompleted && dispatch({ type: 'step/updateStep', step: thisStep })}>
			<div className="card-top-icon-segment">
				<div className="icon-circle">
					<Icon icon={icon} />
				</div>
			</div>
			<div className="card-top-title">
				<h2 ref={reference} id={id} className='text-xl leading-6 font-medium text-coolGray-800 group-hover:underline'>{name}</h2>
				<span className='mt-1 text-coolGray-500'>{description}</span>
			</div>
		</div>

		<UnmountClosed isOpened={showContent} onRest={() => setOpened(showContent)}>
			<div className="card-content">
				{children}
			</div>
			{nextStep != null && <div className="card-btn-area">
				<ButtonArea>
					<ValidatedButton
						validations={nextStepValidations}
						disabled={!actualReadyForNextStep}
						label='Doorgaan ›'
						primary
						onClick={() => dispatch({ type: 'step/updateStep', step: nextStep })} />
				</ButtonArea>
			</div>}
		</UnmountClosed>

		{showSummary && <div className='card-summary-wrapper'>
			<div className={classNames('card-summary', summaryInError && 'card-summary-error')}>
				<div className='card-summary-icon'>
					{summaryInError
						? <Icon icon='akar-icons:triangle-alert-fill' className='text-red-700 h-5 w-5'/>
						: <Icon icon='akar-icons:check' className='text-green-700 h-5 w-5'/>}
				</div>
				<div className='sm:px-4 xl:px-6 flex-1'>
					{summary && (
						<>
							<div className='font-medium text font-inter'>{summary.name}:</div>
							<div className='flex items-center justify-center mx-2'>
								<CalculationInput
									value={summary.value}
									editable={false}
									isResult={true}
									summaryResult={true}
								/>
							</div>
							{summary.unit}
						</>
					)}
					{summaryInError && <div>Kan samenvatting momenteel niet berekenen</div>}
					{!summaryInError && summaryComponent != null && summaryComponent}
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<button
						className="summary-edit-btn"
						onClick={() => {
							dispatch({ type: 'step/updateStep', step: thisStep });
						}}>
						<Icon icon='akar-icons:pencil' className='h-5 w-5'/>
					</button>
				</div>
			</div>
		</div>}
	</section>;
}
