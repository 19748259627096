export function MaintenanceIcon({ ...props }) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='24' height='24' viewBox='0 0 172 172' {...props}>
			<g
				fill='none'
				fill-rule='nonzero'
				stroke='none'
				stroke-width='1'
				stroke-linecap='butt'
				stroke-linejoin='miter'
				stroke-miterlimit='10'
				stroke-dasharray=''
				stroke-dashoffset='0'
				font-family='none'
				font-weight='none'
				font-size='none'
				text-anchor='none'>
				<path d='M0,172v-172h172v172z' fill='none'></path>
				<g fill='currentColor'>
					<path
						d='M151.618,122.41383l-40.95033,-41.03633c-3.3755,-3.38267 -8.84367,-3.397 -12.24067,-0.03583l-1.38317,1.36883l-39.0655,-39.0655c-2.33633,-7.00183 -6.74383,-13.12217 -12.64917,-17.55117l-13.23683,-9.92583c-3.655,-2.73767 -8.76483,-2.37933 -11.98983,0.85283l-3.08167,3.08167c-3.23217,3.225 -3.5905,8.33483 -0.85283,11.98983l9.92583,13.23683c4.429,5.90533 10.54933,10.31283 17.55117,12.64917l39.001,39.001l-1.23983,1.2255c-3.41133,3.3755 -3.42567,8.88667 -0.03583,12.28367l41.05067,41.1295c8.0625,8.0625 21.1345,8.0625 29.20417,0c8.05533,-8.0625 8.05533,-21.1345 -0.00717,-29.20417z'
						opacity='0.35'></path>
					<path d='M20.4035,151.5965c8.09117,8.09117 21.21333,8.09117 29.3045,0l64.95867,-64.8225l-29.16833,-29.44783l-65.09483,64.973c-8.09117,8.084 -8.09117,21.20617 0,29.29733z'></path>
					<path d='M130.53367,61.73367c-5.94117,5.94117 -15.8025,5.57567 -21.2635,-1.0965c-4.74433,-5.805 -3.73383,-14.448 1.5695,-19.75133l16.20383,-16.20383c2.99567,-2.99567 1.61967,-8.19867 -2.50117,-9.18767c-5.9985,-1.43333 -12.47717,-1.6125 -19.1995,-0.15767c-16.69117,3.62633 -29.9925,17.30033 -32.97383,34.11333c-5.30333,29.885 20.296,55.48433 50.181,50.181c16.82017,-2.98133 30.487,-16.28267 34.10617,-32.981c1.45483,-6.72233 1.28283,-13.201 -0.15767,-19.1995c-0.989,-4.12083 -6.18483,-5.49683 -9.1805,-2.50833c-7.18817,7.2025 -15.20767,15.21483 -16.78433,16.7915z'></path>
				</g>
			</g>
		</svg>
	);
}
