import React from 'react';

export default function HorizontalColumnChart({ columns, maxWidth }) {
	const maxValue = Math.max(...columns.map((column) => column.value));

	return <div className='hor-chart'>
		<div className='hor-chart-container'>
			{columns.map(c => <div key={c.color}>
				<div
					className='column-chart-col'
					style={{ background: c.color,	width: (c.value / maxValue) * maxWidth || 0, boxShadow: '0 0 4px rgba(0, 0, 0, 0.05)' }} />
				<span className='hor-chart-label'>{c.label}</span>
			</div>)}
		</div>
	</div>;
}
