export function HeatingHouseIcon(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='24' height='24' viewBox='0 0 172 172' {...props}>
			<g
				fill='none'
				fill-rule='nonzero'
				stroke='none'
				stroke-width='1'
				stroke-linecap='butt'
				stroke-linejoin='miter'
				stroke-miterlimit='10'
				stroke-dasharray=''
				stroke-dashoffset='0'
				font-family='none'
				font-weight='none'
				font-size='none'
				text-anchor='none'>
				<path d='M0,172v-172h172v172z' fill='none'></path>
				<g fill='currentColor'>
					<path
						d='M129,150.5h-86c-11.87517,0 -21.5,-9.62483 -21.5,-21.5v-57.33333h14.33333l50.16667,-35.83333l50.16667,7.16667l14.33333,7.16667v78.83333c0,11.87517 -9.62483,21.5 -21.5,21.5z'
						opacity='0.35'></path>
					<path d='M53.75,121.83333h-14.33333c-1.978,0 -3.58333,-1.60533 -3.58333,-3.58333v-14.33333c0,-1.978 1.60533,-3.58333 3.58333,-3.58333h14.33333c1.978,0 3.58333,1.60533 3.58333,3.58333v14.33333c0,1.978 -1.60533,3.58333 -3.58333,3.58333z'></path>
					<path d='M129.00717,71.30833c-3.81267,-4.29283 -10.52783,-4.29283 -14.3405,0c-10.46333,11.76767 -28.66667,34.55767 -28.66667,50.525c0,19.78717 16.04617,35.83333 35.83333,35.83333c19.78717,0 35.83333,-16.04617 35.83333,-35.83333c0,-15.96733 -18.20333,-38.75733 -28.6595,-50.525zM121.83333,148.70833c-9.89717,0 -17.91667,-8.0195 -17.91667,-17.91667c0,-7.98367 9.10167,-19.37867 14.32617,-25.2625c1.90633,-2.14283 5.26033,-2.14283 7.17383,0c5.23167,5.88383 14.33333,17.27883 14.33333,25.2625c0,9.89717 -8.0195,17.91667 -17.91667,17.91667z'></path>
					<path d='M143.33333,21.5h-114.66667c-7.91917,0 -14.33333,6.41417 -14.33333,14.33333v35.83333c0,7.91917 6.41417,14.33333 14.33333,14.33333h15.566c3.79833,0 7.44617,-1.51217 10.13367,-4.19967l19.93767,-19.93767c6.4715,-6.4715 16.95633,-6.50017 23.4565,-0.05733l3.354,3.31817c0.96033,-1.118 1.8275,-2.20017 2.838,-3.3325c4.52933,-5.10983 11.051,-8.03383 17.88083,-8.03383c6.82983,0 13.3515,2.93117 17.888,8.03383c4.00617,4.50067 7.51783,8.82217 10.77867,13.05767v9.16617c4.26417,-2.47967 7.16667,-7.052 7.16667,-12.34817v-35.83333c0,-7.91917 -6.41417,-14.33333 -14.33333,-14.33333z'></path>
				</g>
			</g>
		</svg>
	);
}
