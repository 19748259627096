import { useEffect, useRef } from 'react';
// @See https://www.emgoto.com/react-table-of-contents/
// Get all headings that are currently visible on the page
const findVisibleSections = (sectionElements) => {
	const visibleHeadings = [];
	Object.keys(sectionElements).forEach((key) => {
		const headingElement = sectionElements[key];
		if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
	});
	return visibleHeadings;
};

export function useScrollSpy(setActiveId, selectors) {
	const sectionElementsReference = useRef({});
	useEffect(() => {
		const callback = (sections) => {
			sectionElementsReference.current = sections.reduce((map, sectionElement) => {
				map[sectionElement.target.id] = sectionElement;
				return map;
			}, sectionElementsReference.current);

			const visibleHeadings = findVisibleSections(sectionElementsReference.current);

			const oneSectionVisible = visibleHeadings.length === 1;
			if (oneSectionVisible) {
				setActiveId(visibleHeadings[0].target.id);
			} else if (visibleHeadings.length > 1) {
				// If there is more than one visible heading,
				// choose the one that is closest to the top of the page
				const getIndexFromId = (id) => sectionElements.findIndex((section) => section.id === id);

				const sortedVisibleHeadings = visibleHeadings.sort(
					(a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
				);

				setActiveId(sortedVisibleHeadings[0].target.id);
			}
		};

		const scrollSpy = new IntersectionObserver(callback, {
			rootMargin: '500px',
		});

		const sectionElements = Array.from(document.querySelectorAll(selectors));
		sectionElements.forEach((element) => scrollSpy.observe(element));

		return () => scrollSpy.disconnect();
	}, [ selectors, setActiveId ]);
}
