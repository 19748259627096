import { roundNumberToOneDecimal } from '../../../lib/rounding';

export function useCommonCalculationProps({ initialValue, warnMin, warnMinText, warnMax, warnMaxText, value }) {
	const isDirty = initialValue != null && roundNumberToOneDecimal(value) !== roundNumberToOneDecimal(initialValue);
	const warningMessageRaw =
		(warnMin && value < warnMin && (warnMinText || `Deze waarde is onder ${roundNumberToOneDecimal(warnMin)}`)) ||
		(warnMax && value > warnMax && (warnMaxText || `Deze waarde is boven ${roundNumberToOneDecimal(warnMax)}`));

	return { warningMessage: Number.isNaN(warningMessageRaw) ? null : warningMessageRaw, isDirty };
}
