export function HousekeeperIcon({ ...props }) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='24' height='24' viewBox='0 0 172 172' {...props}>
			<g
				fill='none'
				fill-rule='nonzero'
				stroke='none'
				stroke-width='1'
				stroke-linecap='butt'
				stroke-linejoin='miter'
				stroke-miterlimit='10'
				stroke-dasharray=''
				stroke-dashoffset='0'
				font-family='none'
				font-weight='none'
				font-size='none'
				text-anchor='none'>
				<path d='M0,172v-172h172v172z' fill='none'></path>
				<g fill='currentColor'>
					<path d='M94.17,116.17883l-8.31333,-41.56667c-2.00667,-10.03333 -10.82167,-17.27167 -21.07,-17.27167h-0.28667h-7.16667h-0.28667c-10.24833,0 -19.06333,7.23833 -21.07,17.27167l-8.31333,41.56667c-1.36167,6.59333 3.72667,12.82833 10.535,12.82833h45.43667c6.80833,0 11.89667,-6.235 10.535,-12.82833z'></path>
					<path
						d='M60.91667,14.3405c-9.89717,0 -17.91667,8.0195 -17.91667,17.91667c0,9.89717 8.0195,17.91667 17.91667,17.91667c9.89717,0 17.91667,-8.0195 17.91667,-17.91667c0,-9.89717 -8.0195,-17.91667 -17.91667,-17.91667z'
						opacity='0.35'></path>
					<ellipse
						cx='-1.7686'
						cy='7.4253'
						transform='rotate(-45.001) scale(7.16667,7.16667)'
						rx='2.002'
						ry='1.001'
						opacity='0.35'></ellipse>
					<path d='M69.17983,157.67383h-16.52633c-3.5045,0 -6.493,-2.52983 -7.06633,-5.99133l-6.1705,-37.00867h43l-6.1705,37.00867c-0.57333,3.4615 -3.56183,5.99133 -7.06633,5.99133z'></path>
					<path d='M136.16667,121.83333h-14.33333v-79.1415c0,-3.784 3.0745,-6.8585 6.8585,-6.8585h0.60917c3.79117,0 6.86567,3.0745 6.86567,6.8585z'></path>
					<path d='M111.37717,129h35.25283c-1.66983,-8.32767 -8.17717,-14.33333 -15.68783,-14.33333h-3.87717c-7.51783,0 -14.02517,6.00567 -15.68783,14.33333z'></path>
					<path
						d='M146.62283,129h-35.25283c-0.03583,0.16483 -0.09317,0.31533 -0.12183,0.48017l-3.64783,20.84067c-0.31533,1.806 0.09317,3.6765 1.11083,5.0955c1.02483,1.42617 2.55133,2.25033 4.16383,2.25033h32.25c1.6125,0 3.139,-0.82417 4.15667,-2.25033c1.01767,-1.419 1.43333,-3.29667 1.11083,-5.0955l-3.64783,-20.84067c-0.0215,-0.16483 -0.086,-0.31533 -0.12183,-0.48017z'
						opacity='0.35'></path>
				</g>
			</g>
		</svg>
	);
}
