export const initialStepReducerState = {
	stepReducer: {
		step: 'project',
		seenSteps: new Set([ 'project' ]),
	},
};

export function stepReducer(state = initialStepReducerState, action) {
	switch (action.type) {
	case 'step/updateStep': {
		return {
			...state,
			stepReducer: {
				step: action.step,
				seenSteps: state.stepReducer.seenSteps.add(action.step),
			},
		};
	}

	default: {
		return state;
	}
	}
}
