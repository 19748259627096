import ReactModal from 'react-modal';

export default function Modal({ title, isOpen, onRequestClose, children, maxWidth }) {
	return <ReactModal
		isOpen={isOpen}
		onRequestClose={onRequestClose}
		style={{
			content: {
				border: 'none',
				boxShadow: '1px 2px 16px rgba(0, 0, 0, 0.3)',
				width: '100%',
				maxWidth,
				margin: '0 auto',
				position: 'relative',
				inset: 'inherit',
				maxHeight: 'calc(100vh - 40px)',
				overflow: 'auto',
			},
			overlay: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				background: 'rgba(0, 0, 0, 0.7)',
			},
		}}>
		<div className="modal-toolbar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '32px' }}>
			<div style={{ flex: 1 }}>
				<span className="modal-title">{title}</span>
			</div>
			{onRequestClose && <div style={{ width: '32px', height: '32px' }} onClick={onRequestClose}>
				<span>&times;</span>
			</div>}
		</div>
		<div className="modal-content">
			{children}
		</div>
	</ReactModal>;
}