import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

import { classNames } from '../../../lib/classNames';

export const CalculationInput = forwardRef(
	(
		{
			value,
			editable,
			onValueChange,
			isDirty,
			strictMin,
			strictMax,
			mustBeInteger,
			isResult,
			summaryResult,
			decimals = 1,
			prefix,
			suffix,
			textLeft,
			id,
			thousandSeparator = '.',
		},
		reference
	) => {
		return (
			<div className={classNames('calculation-input', !editable && 'calculation-input-uneditable')}>
				<NumberFormat
					ref={reference}
					id={id}
					autoComplete='off'
					thousandsGroupStyle='thousand'
					value={value}
					displayType={editable ? 'input' : 'text'}
					allowNegative={false}
					decimalScale={decimals}
					fixedDecimalScale={true}
					onValueChange={onValueChange}
					decimalSeparator=','
					thousandSeparator={thousandSeparator}
					prefix={(value < 0 ? '- ' : '') + (prefix ?? '')}
					suffix={suffix}
					allowedDecimalSeparators={mustBeInteger ? [] : [ '.', ',' ]}
					className={classNames(
						'form-control transition duration-300 block  w-full p-1 text-right tabular-nums border-0 focus:border-blue-500 focus:ring-0 sm:text-sm',
						editable ? 'bg-white border-b border-t border-l border-r border-gray-300 text-blueGray-500 font-semibold input-editable' : 'bg-transparent text-right',
						isDirty && 'text-blue-500',
						isResult && '!font-extrabold !text-normal',
						// editable && isResult && 'ring-1 ring-blue-500 ring-opacity-20 ',
						summaryResult && '!font-inter !text-left font-bold !text-coolGray-600 !text-base !w-auto !p-0',
						textLeft && '!text-left'
					)}
					isAllowed={({ formattedValue, floatValue }) => {
						if (formattedValue === '') return true;
						if (floatValue == null) {
							return formattedValue === '';
						} else {
							return (
								(!mustBeInteger || Number.isInteger(floatValue)) &&
								(!strictMin || strictMin <= floatValue) &&
								(!strictMax || floatValue <= strictMax)
							);
						}
					}}
				/>
			</div>
		);
	}
);
