import { createContext, useEffect, useMemo, useReducer } from 'react';
import reduceReducers from 'reduce-reducers';

import { buildingReducer, initialBuildingReducerState } from './buildingReducer';
import { financingReducer, initialFinancingReducerState } from './financingReducer';
import { housingReducer, initialHousingReducerState } from './housingReducer';
import { incomeReducer, initialIncomeReducer } from './incomeReducer';
import { initialInvestingReducerState, investingReducer } from './investingReducer';
import { initialServiceReducer, serviceReducer } from './serviceReducer';
import { initialStepReducerState, stepReducer } from './stepReducer';
import { initialTerrainReducerState, terrainReducer } from './terrainReducer';
import { initialValueReducer, valueReducer } from './valueReducer';

const initialState = {
	...initialStepReducerState,
	...initialHousingReducerState,
	...initialTerrainReducerState,
	...initialBuildingReducerState,
	...initialInvestingReducerState,
	...initialServiceReducer,
	...initialIncomeReducer,
	...initialValueReducer,
	...initialFinancingReducerState,
};

export const bvoToVvoMultiplier = 0.8;
export const localstorageKey = 'platform31-haalbaarheidsscan-state';

export const BouwCalculatorReducerContext = createContext(null);
export const frontendReducer = reduceReducers(
	housingReducer,
	terrainReducer,
	buildingReducer,
	stepReducer,
	investingReducer,
	serviceReducer,
	incomeReducer,
	valueReducer,
	financingReducer
);

function wrappedFrontendReducer(state, action) {
	if (action.type === 'replaceState') return action.state;
	return frontendReducer(state, action);
}

export const StepsStoreProvider = ({ children }) => {
	const [ state, dispatch ] = useReducer(wrappedFrontendReducer, initialState);
	// Important(!): memoize array value. Else all context consumers update on *every* render
	const store = useMemo(() => [ state, dispatch ], [ state ]);

	// useEffect(() => {
	// 	console.log('New state', state);
	// }, [ state ]);

	useEffect(() => {
		if (store[0]?.stepReducer?.seenSteps?.size <= 1) return;
		saveToLocalStorage(store[0]);
	}, [ store ]);

	return <BouwCalculatorReducerContext.Provider value={store}> {children} </BouwCalculatorReducerContext.Provider>;
};

function saveToLocalStorage(state) {
	if (localStorage == null) return;

	localStorage.setItem(localstorageKey, JSON.stringify(state, (_key, value) => (value instanceof Set ? [ ...value ] : value)));
}