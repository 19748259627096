import NumberFormat from 'react-number-format';

export function FormattedNumber({ value, decimalScale = 2, prefix, ...props }) {
	return <NumberFormat
		displayType='text'
		thousandSeparator='.'
		decimalSeparator=','
		value={value}
		decimalScale={decimalScale}
		prefix={(value < 0 ? '  ' : '') + (prefix ?? '')}
		{...props} />;
}
