// Income from year 2 = verhuur + epv + service costs volgens huurcomissie

import { createSelector } from 'reselect';

import { housing } from '../housingReducer';
import {
	energyPerformanceFeeFreeSector,
	energyPerformanceFeeSocialHousing,
	incomeCommonArea,
	incomeFreeSectorHousingFirstYear,
	incomeRentalSocialHousingFirstYear,
} from '../incomeReducer';
import {
	serviceCostsCommonArea,
	serviceCostsFreeSector,
	serviceCostsSocialHousing,
	totalServiceCostsVsHuurcommissieRatio,
} from '../serviceReducer';
import { value } from '../valueReducer';

export const incomeSocial = createSelector(
	incomeRentalSocialHousingFirstYear,
	energyPerformanceFeeSocialHousing,
	serviceCostsSocialHousing,
	totalServiceCostsVsHuurcommissieRatio,
	(rentalIncome, epv, serviceCosts, serviceCostsVsHuurcommissieRatio) => {
		return rentalIncome + epv + serviceCosts * serviceCostsVsHuurcommissieRatio;
	}
);

export const incomeFreeSector = createSelector(
	incomeFreeSectorHousingFirstYear,
	energyPerformanceFeeFreeSector,
	serviceCostsFreeSector,
	totalServiceCostsVsHuurcommissieRatio,
	(rentalIncome, epv, serviceCosts, serviceCostsVsHuurcommissieRatio) => {
		return rentalIncome + epv + serviceCosts * serviceCostsVsHuurcommissieRatio;
	}
);

export const incomeFromCommonArea = createSelector(
	incomeCommonArea,
	serviceCostsCommonArea,
	totalServiceCostsVsHuurcommissieRatio,
	(rentalIncome, serviceCosts, serviceCostsVsHuurcommissieRatio) => {
		return rentalIncome + serviceCosts * serviceCostsVsHuurcommissieRatio;
	}
);

export const priceBuyHome = createSelector(housing, value, (housing, value) => {
	return housing.areaBvo.buy * value.VONBuyPerM2;
});

export const saleIncomeFromBuyHomes = createSelector(housing, priceBuyHome, (housing, priceBuyHome) => {
	return housing.noHouses.buy * priceBuyHome;
});

export const totalIncomeSecondYear = createSelector(
	incomeSocial,
	incomeFreeSector,
	// incomeFromCommonArea,
	(incomeSocial, incomeFreeSector, incomeFromCommonArea) => {
		return incomeSocial + incomeFreeSector; // + incomeFromCommonArea; // apparently common area not needed
	}
);
