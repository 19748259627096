import { classNames } from '../../lib/classNames';

export function PopperArrow({ placement, style }) {
	return (
		<div
			style={style}
			className={classNames(
				`popover-arrow`,
				placement?.startsWith('top') && '-bottom-1',
				placement?.startsWith('bottom') && '-top-1',
				placement?.startsWith('left') && '-right-1',
				placement?.startsWith('right') && '-left-1'
			)}
		/>
	);
}
