import { useEffect, useState } from 'react';

import getMunicipalityData from '../../municipalityData';

export default function MunicipalitySelector({ onChange, value }) {
	const [ loading, setLoading ] = useState(true);
	const [ municipalities, setMunicipalities ] = useState([]);

	useEffect(() => {
		getMunicipalityData()
			.then(setMunicipalities)
			.catch((e) => console.error(e))
			.finally(() => setLoading(false));
	}, []);

	return (
		<select
			className="fc-text-left"
			onChange={(e) => onChange(e.target.value, municipalities.find((m) => m.id == e.target.value))}
			value={value || 'default'}
			style={{ width: '100%' }}>
			<option value='default' disabled={true} hidden={true}>
				-- Kies gemeente
			</option>
			{municipalities.sort(cmpMunicipality).map((m) => (
				<option key={m.id} value={m.id}>
					{m.name}
				</option>
			))}
		</select>
	);
}

function cmpMunicipality(a, b) {
	const special = 'Nederlands Gemiddelde';
	if (a.name == special && b.name != special) {
		return -1;
	} else if (a.name != special && b.name == special) {
		return 1;
	} else {
		return a.name.localeCompare(b.name);
	}
}
