export default async function getMunicipalityData() {
	return [
		{
			id: 9999,
			name: 'Nederlands Gemiddelde',

			taxRateOzb: 0.1115,
			taxSewage: 180,
			taxRateWaterSystem: 0.0330,
			groundLeaseRate: 2.5,

			landCost: {
				social: 225,
				freeSector: 500,
				buy: 750,
			},

			buildCost: {
				social: 2150,
				freeSector: 2300,
				buy: 2300,
			},

			rentExpectation: { // per m2
				social: 9.00,
				freeSector: 14.50,
			},

			wozValue: { // per m2
				social: 2625,
				freeSector: 2625,
				buy: 2625,
			},

			vonValue: { // per m2
				social: 3500,
				freeSector: 3500,
				buy: 3500,
			},
		},

		{
			id: 1,
			name: 'Amsterdam',

			taxRateOzb: 0.0428,
			taxSewage: 144,
			taxRateWaterSystem: 0.012126,
			groundLeaseRate: 2.39,

			landCost: {
				social: 225,
				freeSector: 1000,
				buy: 1400,
			},

			buildCost: {
				social: 2150,
				freeSector: 2260,
				buy: 2260,
			},

			rentExpectation: { // per m2
				social: 12.50,
				freeSector: 22.40,
			},

			wozValue: { // per m2
				social: 4994,
				freeSector: 4994,
				buy: 4994,
			},

			vonValue: { // per m2
				social: 6730,
				freeSector: 6730,
				buy: 6730,
			},
		},

		{
			id: 2,
			name: 'Leusden',

			taxRateOzb: 0.0942,
			taxSewage: 140.20,
			taxRateWaterSystem: 0.0184,

			landCost: {
				social: 225,
				freeSector: 400,
				buy: 450,
			},

			buildCost: {
				social: 2120,
				freeSector: 2210,
				buy: 2210,
			},

			rentExpectation: { // per m2
				social: 9.10,
				freeSector: 17.80,
			},

			wozValue: { // per m2
				social: 2986,
				freeSector: 2986,
				buy: 2986,
			},

			vonValue: { // per m2
				social: 3540,
				freeSector: 3540,
				buy: 3540,
			},
		},

		{
			id: 3,
			name: 'Utrecht',

			taxRateOzb: 0.0725,
			taxSewage: 228.32,
			taxRateWaterSystem: 0.0226,

			landCost: {
				social: 295,
				freeSector: 350,
				buy: 450,
			},

			buildCost: {
				social: 2240,
				freeSector: 2320,
				buy: 2320,
			},

			rentExpectation: { // per m2
				social: 12.10,
				freeSector: 18.40,
			},

			wozValue: { // per m2
				social: 3138,
				freeSector: 3138,
				buy: 3138,
			},

			vonValue: { // per m2
				social: 4850,
				freeSector: 4850,
				buy: 4850,
			},
		},

		{
			id: 4,
			name: 'Zeist',

			taxRateOzb: 0.0668,
			taxSewage: 188.40,
			taxRateWaterSystem: 0.012483,

			landCost: {
				social: 180,
				freeSector: 325,
				buy: 400,
			},

			buildCost: {
				social: 2120,
				freeSector: 2210,
				buy: 2210,
			},

			rentExpectation: { // per m2
				social: 11.20,
				freeSector: 14.10,
			},

			wozValue: { // per m2
				social: 2994,
				freeSector: 2994,
				buy: 2994,
			},

			vonValue: { // per m2
				social: 4580,
				freeSector: 4580,
				buy: 4580,
			},
		},
	];
}
