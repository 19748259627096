import { classNames } from '../../../lib/classNames';

export default function Button({ disabled, primary, className, label, onClick, children, style }) {
	return (
		<button
			disabled={disabled}
			className={classNames('btn', primary && 'btn-primary')}
			onClick={onClick}
			style={style}>
			{label || children}
		</button>
	);
}
