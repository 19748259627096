import { classNames } from '../../../lib/classNames';
import { CalculationInput } from '../calctable/CalculationInput';

export function CostInput({ value, isDirty, unit, helpText, decimals = 2, unitOnTop, ...props }) {
	return <div className='cost-input'>
		<div className={classNames('cost-input-field', unitOnTop && 'cost-input-field-rev')}>
			<div className="cost-input-input">
				<CalculationInput
					value={value}
					editable={true}
					isDirty={isDirty}
					decimals={decimals}
					{...props}
					// onValueChange={onValueChange}
					// mustBeInteger={mustBeInteger}
				/>
			</div>
			<div className='cost-input-unit unit'>
				<span className='text-sm text-blueGray-500 w-32'>&nbsp;{unit}</span>
			</div>
		</div>
		{helpText && <div className='remark'>{helpText}</div>}
	</div>;
}
