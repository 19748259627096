import { Transition } from '@headlessui/react';
import { useCallback, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { PopperArrow } from './PopoverArrow';

export function Tooltip({ children, text }) {
	const [ referenceElement, setReferenceElement ] = useState(null);
	const [ popperElement, setPopperElement ] = useState(null);
	const { styles, attributes, update } = usePopper(referenceElement, popperElement);

	const [ visible, setVisible ] = useState(false);
	const hideTooltip = useCallback(() => setVisible(false), [ setVisible ]);
	const showTooltip = useCallback(() => setVisible(true), [ setVisible ]);

	useEffect(() => {
		referenceElement?.addEventListener('focus', showTooltip);
		referenceElement?.addEventListener('blur', hideTooltip);
		referenceElement?.addEventListener('mouseenter', showTooltip);
		referenceElement?.addEventListener('mouseleave', hideTooltip);

		return () => {
			referenceElement?.removeEventListener('focus', showTooltip);
			referenceElement?.removeEventListener('blur', hideTooltip);
			referenceElement?.removeEventListener('mouseenter', showTooltip);
			referenceElement?.removeEventListener('mouseleave', hideTooltip);
		};
	}, [ hideTooltip, referenceElement, showTooltip ]);

	if (!text) return <div ref={setReferenceElement}>{children}</div>;
	return (
		<>
			<div ref={setReferenceElement}>{children}</div>

			<div ref={setPopperElement} style={styles.popper} {...attributes.popper} className='z-20'>
				<Transition
					show={visible}
					beforeEnter={() => update?.()}
					enter='transition ease-out duration-200'
					enterFrom='opacity-0 translate-y-1'
					enterTo='opacity-100 translate-y-0'
					leave='transition ease-in duration-150'
					leaveFrom='opacity-100 translate-y-0'
					leaveTo='opacity-0 translate-y-1'>
					<div className='bg-black text-white p-2 shadow-lg rounded flex items-center justify-center text-sm'>
						{text}
						<PopperArrow placement={attributes?.popper?.['data-popper-placement']} style={styles.arrow} />
					</div>
				</Transition>
			</div>
		</>
	);
}
