import Tippy from '@tippyjs/react';
import React from 'react';
import 'tippy.js/animations/shift-away-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

/**
 * @classdesc - TippyPopOver - A React component that wraps the Tippy.js library.
 * @see https://atomiks.github.io/tippyjs/v6/animations/
 */
export function TippyPopover({ children, interactive, buttonContent, trigger = 'mouseenter focus', ...props }) {
	// Important do not use the scale animation! It causes the popover height to vary which interfers with any
	// virtualized list!
	return (
		<Tippy
			content={children}
			theme='light-border'
			animation='shift-away-subtle'
			trigger={trigger}
			duration={[ 250, 150 ]}
			aria={{ content: 'labelledby' }}
			// triger={trigger}
			{...props}>
			<button type='button'>{buttonContent}</button>
		</Tippy>
	);
}
