import Modal from '../lib/Modal';

export default function FeedbackModal({ open, onRequestClose }) {
	return <Modal title="Feedback geven" onRequestClose={onRequestClose} isOpen={open} maxWidth="700px">
		<div className="longform-text">
			<p>We horen graag feedback op de haalbaarheidsscan!</p>
			<ul>
				<li>Voldoet het aan uw verwachtingen?</li>
				<li>Heeft het u geholpen, en hoe?</li>
				<li>Komt u ergens niet uit, heeft u hulp nodig?</li>
				<li>Heeft u tips om de haalbaarheidsscan beter onder de aandacht te brengen?</li>
			</ul>
			<p>Stuur uw e-mail aan Annette Duivenvoorden (<a href="mailto:annette.duivenvoorden@platform31.nl">annette.duivenvoorden@platform31.nl</a>) en Jasper Klapwijk (<a href="mailto:jasper@kantelingen.nl">jasper@kantelingen.nl</a>)</p>
		</div>
	</Modal>;
}